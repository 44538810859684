import {
  ref,
  computed,
  onBeforeUnmount,
  onBeforeMount,
  inject,
  watch
} from 'vue';

import DeviceService from '@/services/device';
import TaskService from '@/services/tasks';
import { useStore } from 'vuex';
import DeviceConfig from 'src/config/device-config';
import firebaseMessaging from 'src/config/firebase-config';
import { getSortedTask } from 'src/utils/task';
import trainingStatuses from 'src/config/training-status-config.js';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import { handleMessage } from 'src/utils/fcm';

export function useActiveDevices() {
  const toast = inject('toast');
  const emitter = inject('emitter');
  const message = ref(firebaseMessaging);
  const tasks = ref([]);
  const taskOptions = ref([]);
  const trainedTasks = ref([]);
  const isLoadingTasks = ref(false);
  const taskInputRefs = ref({});
  const allDevices = ref([]);
  const devicesDetails = ref({});
  const activeDevicesDict = ref({});
  const isLoadingDevices = ref(false);
  const polling = ref(null);
  const activeDevicesPolling = ref(null);
  const activeDevicesPollingTime = ref(10000);
  const pollingTime = ref(1000);
  // Modal Variables
  const showSchedule = ref(false);
  const deviceToSchedule = ref();
  const startEndTime = ref([]);
  const selectedDays = ref([]);
  const isScheduling = ref(false);
  const isPolled = ref(false);
  const devicesCount = ref(0);
  const activeDevices = ref([]);

  // Store Variable
  const store = useStore();
  // const fcmToken = computed(() => store.state.fcm.fcmToken);
  const organization = computed(() => store.state.auth.organization);

  const activeDevicesList = computed(() => {
    const activeDevices = allDevices.value.filter(d => d.active);
    const sortedDevices = activeDevices.sort((a, b) => b.starred - a.starred);
    return sortedDevices;
  });

  const activeDevicesCount = computed(() => {
    const activeDevices = allDevices.value.filter(d => d.active);
    return activeDevices.length;
  });

  watch(
    () => allDevices.value,
    value => {
      devicesDetails.value = value.reduce((res, dev) => {
        res[dev.Serial_number] = dev;
        return res;
      }, {});
    }
  );

  const decreaseDeviceTimeout = async () => {
    const temp = {};
    Object.entries(activeDevicesDict.value).forEach(
      ([Serial_number, timeout]) => {
        if (timeout === 1) {
          updateDevice({
            Serial_number,
            active: false,
            rtc_thread_status: false
          });
        } else temp[Serial_number] = timeout - 1;
      }
    );
    activeDevicesDict.value = temp;
  };

  onBeforeMount(() => {
    getTasks();
    getAllTrainedTasks();
    getAllDevices();
    activeDevicesPolling.value = setInterval(
      getActiveDevices,
      activeDevicesPollingTime.value
    );
    // polling.value = setInterval(decreaseDeviceTimeout, pollingTime.value);
    // handleMessage(message.value, onMessageReceived);
  });

  onBeforeUnmount(() => {
    // clearInterval(polling.value);
    clearInterval(activeDevicesPolling.value);
  });

  async function getActiveDevices() {
    if (isPolled.value) return;

    isPolled.value = true;

    const [error, data] = await DeviceService.getActiveDeviceStatus();

    if (error) {
      isPolled.value = false;
      return;
    }

    isPolled.value = false;

    data.forEach(device => {
      if (device.Task && typeof device.Task === 'object')
        device.Task = device.Task.id;
    });

    const activeDevicesMap = new Map(
      data.map(device => [device.Serial_number, true])
    );

    const updatedAllDevices = allDevices.value.map(device => {
      const isActive = activeDevicesMap.has(device.Serial_number);
      if (isActive) handleDeviceStatus({ ...device, active: isActive });
      return { ...device, active: isActive };
    });

    devicesCount.value = updatedAllDevices.length;
    activeDevices.value = updatedAllDevices.filter(device => device.active);
    const dataLookup = Object.fromEntries(
      data.map(item => [item.Serial_number, item])
    );

    const mergedData = activeDevices.value.map(device => ({
      ...device,
      ...(dataLookup[device.Serial_number] || {})
    }));
    activeDevices.value = mergedData;
    // activeDevicesList.value = activeDevices.value;

    isLoadingDevices.value = false;
  }

  async function getTasks() {
    isLoadingTasks.value = true;
    const [error, data] = await TaskService.fetchTasks(false);
    if (error) return;
    tasks.value = data;
    taskOptions.value = getSortedTask(data);
    isLoadingTasks.value = false;
  }

  async function getAllTrainedTasks() {
    const [error, data] = await TaskService.fetchTrainedTasksByStatus(
      trainingStatuses.trained,
      false
    );
    if (error) return;
    trainedTasks.value = data.map(e => e.task);
  }

  async function getAllDevices() {
    isLoadingDevices.value = true;
    const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
      organization.value,
      true
    );
    isLoadingDevices.value = false;
    if (error) return;
    allDevices.value = [...data];
  }
  const handleLiveStream = data => {
    const deviceData = data;
    const serialNumber = deviceData.device;
    emitter.emit(serialNumber, deviceData);
  };

  // async function handleDeviceStatus(device) {
  //   if (typeof device !== 'object' && device.length > 1) {
  //     const [error, data] = await DeviceService.getStatusOfActiveDevices(
  //       device,
  //       this.organization
  //     );
  //     if (data) {
  //       const deviceStatus = { ...data[0].Device, ...data[0] };
  //       const serialNumber = deviceStatus.Serial_number;
  //       const { uploading, downloading } = deviceStatus;

  //       this.activeDevices = [...this.activeDevices];

  //       this.$emit('updateDeviceList', {
  //         ...deviceStatus,
  //         uploading,
  //         active: true,
  //         downloading
  //       });
  //     }
  //   }
  // };

  const handleDeviceStatus = data => {
    // console.log('handleDeviceStatus', data);
    const deviceStatus = data;
    const { uploading, downloading } = deviceStatus;
    const serialNumber = deviceStatus.Serial_number;
    activeDevices;
    activeDevicesDict.value = {
      ...activeDevicesDict.value,
      [serialNumber]: 20
    };
    // activeDevices.value.push(data);
    // console.log(activeDevices.value);
    updateDevice({ ...deviceStatus, uploading, downloading });
  };

  const handleDeviceSetting = data => {
    const deviceSettings = data;

    if (typeof deviceSettings !== 'object') return;

    const device = deviceSettings?.Device;
    activeDevicesDict.value = {
      ...activeDevicesDict.value,
      [device.Serial_number]: 20
    };

    deviceSettings['Task'] = deviceSettings.Task.id;
    delete deviceSettings['Device'];
    updateDevice({ ...device, ...deviceSettings });
  };

  function onMessageReceived(payload) {
    const handlers = {
      [DeviceConfig.device_status]: handleDeviceStatus,
      [DeviceConfig.device_setting]: handleDeviceSetting,
      [DeviceConfig.web_rtc_stream_offer]: handleLiveStream
    };

    const { data, type } = payload?.data;
    handlers[type] && handlers[type](JSON.parse(data));
  }

  function updateDevice(deviceDetails) {
    const { Serial_number } = deviceDetails;
    const temp = [...allDevices.value];
    const index = temp.findIndex(v => v.Serial_number === Serial_number);
    if (temp[index]) {
      temp[index] = {
        ...temp[index],
        ...deviceDetails
      };
    }
    allDevices.value = temp;
  }

  function updateDeviceSettings(deviceDetails) {
    return new Promise(async resolve => {
      const isUpdated = await updateDeviceSettingsAsync(deviceDetails);
      resolve();
    });
  }

  async function updateDeviceSettingsAsync(deviceDetails) {
    const { id, Serial_number, key, value } = deviceDetails;
    const oldValue = getOldValues(allDevices, Serial_number, key);
    const payload = getPayloadForDeviceSettings(key, value);
    updateDevice({ Serial_number, ...payload });
    const [error] = await DeviceService.updateDeviceSettings(
      organization.value,
      id,
      payload,
      false
    );
    if (error) {
      toast.error('Unable to update!');
      updateDevice({ Serial_number, ...oldValue });
      return false;
    }
    return true;
  }

  async function updateDeviceSettingsAndActiveDevices(deviceDetails) {
    try {
      const isUpdated = await updateDeviceSettingsAsync(deviceDetails);
      if (!isUpdated) return;
      updateActiveDevices(deviceDetails);
    } catch (error) {
      toast.error('An unexpected occur has occured. Please refresh the page.');
    }
  }

  function updateActiveDevices(deviceDetails) {
    const index = activeDevices.value.findIndex(
      device => device.id === deviceDetails.id
    );
    if (index === -1 || isPolled.value) return;
    let device = activeDevices.value[index];
    if (
      Array.isArray(deviceDetails.key) &&
      Array.isArray(deviceDetails.value)
    ) {
      deviceDetails.key.forEach((item, keyIndex) => {
        device[item] = deviceDetails.value[keyIndex];
      });
      return;
    }
    device[deviceDetails.key] = deviceDetails.value;
  }

  function getOldValues(allDevices, Serial_number, keys) {
    const oldValue = {};
    const deviceIndex = allDevices.value.findIndex(
      d => d.Serial_number === Serial_number
    );
    const deviceDetails = allDevices.value[deviceIndex];
    if (Array.isArray(keys)) {
      keys.forEach(item => {
        oldValue[item] = deviceDetails[item];
      });
    } else {
      oldValue[keys] = deviceDetails[keys];
    }
    return oldValue;
  }

  function getPayloadForDeviceSettings(keys, values) {
    const payload = {};
    if (Array.isArray(keys) && Array.isArray(values)) {
      keys.forEach((item, index) => {
        payload[item] = values[index];
      });
    } else {
      payload[keys] = values;
    }
    return payload;
  }

  async function handleRecording(deviceDetails) {
    const {
      id,
      Task,
      record_shift,
      Serial_number,
      is_shift_managed,
      isInferenceRunning
    } = deviceDetails;

    if (!isTaskSet(Task)) return;
    if (isShiftBeingManaged(is_shift_managed, 'recording')) return;
    if (isInGuidanceMode(isInferenceRunning)) return;

    await updateDeviceSettingsAndActiveDevices({
      id,
      Serial_number,
      key: 'record_shift',
      value: !record_shift
    });
  }

  function isTaskSet(task) {
    !task && toast.info('Please select a task first!');
    return !!task;
  }

  function isShiftBeingManaged(is_shift_managed, subject) {
    is_shift_managed &&
      toast.info(`Cannot start ${subject} when Start/End Time is enabled.
      Please disabled "Use Start/End Time" on "Devices" page first.`);
    return is_shift_managed;
  }

  function isInGuidanceMode(isInferenceRunning, action = 'start') {
    isInferenceRunning &&
      toast.info(`Cannot ${action} recording when task is being performed!`);
    return isInferenceRunning;
  }

  async function handleInference(deviceDetails) {
    const {
      id,
      Task,
      record_shift,
      Serial_number,
      is_shift_managed,
      isInferenceRunning
    } = deviceDetails;

    if (!isTaskSet(Task)) return;
    if (!isTrainedTask(Task)) return;
    if (isShiftBeingManaged(is_shift_managed, 'task')) return;
    if (isShiftBeingRecorded(record_shift)) return;

    const payload = {
      isInferenceRunning: !isInferenceRunning
    };
    if (isInferenceRunning) payload['recordInference'] = false;
    else payload['recordInference'] = true;

    await updateDeviceSettingsAndActiveDevices({
      id,
      Serial_number,
      key: Object.keys(payload),
      value: Object.values(payload)
    });
  }

  function isTrainedTask(taskId) {
    const isTaskTrained = trainedTasks.value.some(task => task.id === taskId);
    !isTaskTrained && toast.info('Cannot start inference on Un-trained Task!');
    return isTaskTrained;
  }

  function isShiftBeingRecorded(record_shift, action = 'start') {
    record_shift &&
      toast.info(`Cannot ${action} task when shift is being recorded!`);
    return record_shift;
  }

  function handleChangeTask(record, taskId) {
    taskInputRefs.value[record.Serial_number]?.blur();
    updateDeviceSettings({ ...record, key: 'Task', value: taskId });
  }

  // Scheduling
  function showScheduleModal(deviceDetails, action) {
    const days_of_week = getDaysOfWeek(deviceDetails, action);
    const { start, end } = getStartEndTimeToPopulate(deviceDetails, action);
    startEndTime.value = [start, end];
    selectedDays.value = JSON.parse(days_of_week);
    showSchedule.value = true;
    deviceToSchedule.value = deviceDetails;
  }

  function getDaysOfWeek(deviceDetails, action) {
    if (action === 'recording') return deviceDetails.day_of_week_for_recording;
    if (action === 'inference') return deviceDetails.day_of_week_for_inference;
    return '[]';
  }

  function getStartEndTimeToPopulate(deviceDetails, action) {
    let start = null;
    let end = null;

    const {
      start_recording_time,
      end_recording_time,
      shift_start_time,
      shift_end_time
    } = deviceDetails;
    if (action === 'recording') {
      start = dateHelper.getTimeFromDate(start_recording_time);
      end = dateHelper.getTimeFromDate(end_recording_time);
    } else if (action === 'inference') {
      start = dateHelper.getTimeFromDate(shift_start_time);
      end = dateHelper.getTimeFromDate(shift_end_time);
    }
    return { start, end };
  }

  async function scheduleRecording() {
    isScheduling.value = true;
    const { id, Serial_number, isInferenceRunning } = deviceToSchedule.value;
    const payload = getSchedulePayload('recording');

    if (isInGuidanceMode(isInferenceRunning, 'schedule')) {
      isScheduling.value = false;
      return;
    }

    await updateDeviceSettingsAndActiveDevices({
      id,
      Serial_number,
      key: payload.keys,
      value: payload.values
    });
    showSchedule.value = false;
    isScheduling.value = false;
  }

  async function scheduleInference() {
    isScheduling.value = true;
    const { id, Serial_number, record_shift } = deviceToSchedule.value;
    const payload = getSchedulePayload('inference');
    if (isShiftBeingRecorded(record_shift, 'schedule')) {
      isScheduling.value = false;
      return;
    }

    await updateDeviceSettingsAndActiveDevices({
      id,
      Serial_number,
      key: payload.keys,
      value: payload.values
    });
    showSchedule.value = false;
    isScheduling.value = false;
  }

  function getSchedulePayload(action) {
    const [start, end] = getStartEndTimeForSchedule();
    const payload = {
      // is_shift_managed: true,
      start_recording_time: null,
      end_recording_time: null,
      shift_start_time: null,
      shift_end_time: null
    };
    if (action === 'recording') {
      payload['start_recording_time'] = start;
      payload['end_recording_time'] = end;
      payload['day_of_week_for_recording'] = JSON.stringify(selectedDays.value);
    } else if (action === 'inference') {
      payload['shift_start_time'] = start;
      payload['shift_end_time'] = end;
      payload['day_of_week_for_inference'] = JSON.stringify(selectedDays.value);
    }
    // console.log('schedule payload:', payload)
    return { keys: Object.keys(payload), values: Object.values(payload) };
  }

  function getStartEndTimeForSchedule() {
    if (!startEndTime.value) return [null, null]
    const [start, end] = startEndTime.value;
    if (!start.$d && !end.$d) {
      toast.info('Schedule time is not properly selected!');
      isScheduling.value = false;
      return;
    }
    const startDate = dateHelper.getFormattedDate(start.$d);
    const endDate = dateHelper.getFormattedDate(end.$d);
    const [startTime] = new Date(start.$d).toTimeString().split(' ');
    const [endTime] = new Date(end.$d).toTimeString().split(' ');
    return [startDate + 'T' + startTime, endDate + 'T' + endTime];
  }

  return {
    taskOptions,
    updateDevice,
    isLoadingTasks,
    devicesDetails,
    handleRecording,
    handleInference,
    isLoadingDevices,
    showSchedule,
    showScheduleModal,
    activeDevicesList,
    activeDevices,
    scheduleRecording,
    scheduleInference,
    updateDeviceSettings,
    updateDeviceSettingsAndActiveDevices,
    isScheduling,
    selectedDays,
    startEndTime,
    activeDevicesCount,
    taskInputRefs,
    handleChangeTask,
  };
}
